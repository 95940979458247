var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var ExportConfigService = /** @class */ (function (_super) {
    __extends(ExportConfigService, _super);
    function ExportConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /*
     * 新建模板
     */
    ExportConfigService.prototype.createExportConfig = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.createExportConfig,
            data: {
                items: data.items,
                name: data.name,
                exportType: data.exportType,
                principalId: data.principalId,
                principalName: data.principalName,
                titleStartCol: data.titleStartCol,
                titleStartRow: data.titleStartRow,
                dataStartRow: data.dataStartRow,
                horiCel: data.horiCel,
                isEnablePublicInfo: data.isEnablePublicInfo,
                fileType: data.fileType,
            },
            loading: loading,
        });
    };
    /**
     * 删除模板
     */
    ExportConfigService.prototype.deleteExportConfig = function (id, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.deleteExportConfig,
            data: {
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 修改模板配置
     */
    ExportConfigService.prototype.exportConfigModify = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.exportConfigModify,
            data: {
                id: data.id,
                items: data.items,
                name: data.name,
                exportType: data.exportType,
                principalId: data.principalId,
                principalName: data.principalName,
                titleStartCol: data.titleStartCol,
                titleStartRow: data.titleStartRow,
                dataStartRow: data.dataStartRow,
                horiCel: data.horiCel,
                isEnablePublicInfo: data.isEnablePublicInfo,
                fileType: data.fileType,
            },
            loading: loading,
        });
    };
    /**
     * 获取所有模板
     */
    ExportConfigService.prototype.getAllTemplate = function (data, page, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getAllTemplate,
            data: data,
            page: page,
            loading: loading,
        });
    };
    /**
     * 获取所有模板选项
     */
    ExportConfigService.prototype.getAllConfigs = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getAllConfigs,
            data: data,
            loading: loading,
        });
    };
    /**
     * 获取各个委托方调记字段
     */
    ExportConfigService.prototype.getFollowConfigItems = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getFollowConfigItems,
            data: data,
            loading: loading,
        });
    };
    /**
     * 获取各个委托方主键关联字段
     */
    ExportConfigService.prototype.getPrimaryProperty = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getPrimaryProperty,
            data: {
                principalId: data.principalId,
            },
            loading: loading,
        });
    };
    /**
     * 获取指定模板
     */
    ExportConfigService.prototype.getTemplate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getTemplate,
            data: data,
            loading: loading,
        });
    };
    /**
     * 解析Excel数据模板头信息
     * @param data
     */
    ExportConfigService.prototype.parserTemplateHeader = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.parserTemplateHeader,
            data: {
                fileId: data.fileId,
                sheetTotals: data.sheetTotals,
                titleStartCol: data.titleStartCol,
                titleStartRow: data.titleStartRow,
            },
            loading: loading,
        });
    };
    /**
     * 获取模板列表
     * @param data
     */
    ExportConfigService.prototype.getTemplateByPrin = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportConfigController.getTemplateByPrin,
            data: data,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], ExportConfigService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ExportConfigService.prototype, "createExportConfig", null);
    __decorate([
        Debounce()
    ], ExportConfigService.prototype, "deleteExportConfig", null);
    __decorate([
        Debounce()
    ], ExportConfigService.prototype, "exportConfigModify", null);
    __decorate([
        Debounce()
    ], ExportConfigService.prototype, "parserTemplateHeader", null);
    __decorate([
        Debounce()
    ], ExportConfigService.prototype, "getTemplateByPrin", null);
    return ExportConfigService;
}(Service));
export { ExportConfigService };
